

.App-header {
    grid-column: 1 / 13;
    grid-row: 1;
    position: relative;
    z-index: 2;
    align-self: flex-start;
    justify-self: center;
    margin-left: -6%;

    img {
        max-width: 260px;
    }

    .guillotine-logo  {
        // filter: drop-shadow(0 0 35px $pink-500);
        padding: 1.2em 0;
        
     }
         
     @media screen and (min-width: $tablet-wide) {
        grid-column: 2 / 6;
        grid-row: 1;
        align-self: flex-end;
        margin-left: -3%;
        justify-self: start;

        img {
            max-width: 418px;
        }

     }


}