
// Brand Colours

$green-100: #EEFFFB;
$green-200: #D7FEF4;
$green-300: #82F4D7;
$green-400: #5BEDC7;
$green-500: #23D7A8;
$green-600: #21C198;
$green-700: #14AB84;
$green-800: #04694F;
$green-900: #002F23;
$green-1000: #0A1B16;

$pink-100: #FFF1F8;
$pink-200: #FAC3DE;
$pink-300: #F487BC;
$pink-400: #F259A3;
$pink-500: #E7197C;
$pink-600: #C70C66;
$pink-700: #A70855;
$pink-800: #7A063F;
$pink-900: #300017;

$grey-100: #EEF5F3;
$grey-200: #D2DAD7;
$grey-300: #A0B1AC;
$grey-400: #7B8D87;
$grey-500: #7B8D87;
$grey-600: #51625D;
$grey-700: #30423D;
$grey-800: #1D2F29;
$grey-900: #0A1B16;

// Defaults

$white: #FFFFFF;
$black: #000403;



// Viewports

$iconSize: 24px;

// Breakpoints

$mobile: 320px;
$mobile-std: 360px;
$mobile-large: 414px;
$mobile-wide: 600px;
$tablet: 640px;
$tablet-wide: 800px;
$desktop: 1180px;
$desktop-wide: 1440px;

