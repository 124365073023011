.button,
button {
    font-family: 'Bowlby One', sans-serif;
    padding: 12px 28px 11px 28px;
    margin: 0;
    border: none;
    background-color: $green-500;
    color: $green-900;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1px;
    transition: all .2s ease-in-out;
    display: inline-flex;
    height: max-content;

    &:hover {
        background-color: $green-400;
        color: $green-1000;
        text-shadow: 0 3px 5px #0000002b;
    }

}

a.link-button {
   @extend .button;
   width: max-content;
}