.App {

    .contact-content {
    
        padding: 1em 0 .75em 0;
        background-color: $green-1000;

        @media screen and (min-width: $tablet-wide) {
            padding-left: 60px;
            padding-right: 60px;
        }


    }

    iframe {
        border: none;
        border: 0;
        max-width: 750px;
        border-radius: 30px;
        overflow:hidden;

        &:after {
            display: block;
            content: ' ';
            height: 30px;
            background-color: red;
            position: absolute;
            width: 30px;
            z-index: 3;
        }


    }

    .iframeframe {
        width: 100%;
        max-width: 750px;
        height: 80px;
        position: relative;
        top: -80px;
        background-color: $green-1000;
        z-index: 5;
        border-radius: 0 0 30px 30px;
       
    }

}