ul.social {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
    gap: 1.2em;
    margin: 2rem 0;

    li {
        
        li.twitter {
            background-image: url(../components/social/twitter.svg);
        }
        
        a {
            width: 24px;
            height: 24px;
            overflow: hidden;
            display: flex;
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center center;
            text-indent: -999em;
            transition: all .1s ease-in-out;

            &:hover {
                transform: scale(1.2);
            }

        }

    }

}