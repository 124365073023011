.bowlby {
    font-family: 'Bowlby One', sans-serif;
}

.accent-text {
    color: $green-400;
    font-weight: bold;
    font-style: italic;
}

a {
    color: $green-400;
    &:hover {
        color: $green-300;
        text-decoration: none;
 
    }
}