body {
    background-color: $green-900;
    color: white;
    font-family: 'Roboto Mono', monospace;
}

.App {

    padding-bottom: 0;
    background: linear-gradient(180deg, rgba(10, 27, 22, 0) 0%, #0A1B16 100%);
    background-size: 100% 128px;
    background-position: bottom center;
    background-repeat: no-repeat;
    min-height: 100vh;

    display: grid;
    grid-template-columns: 30px repeat(10, 1fr) 30px;

    @media screen and (min-width: $tablet) {
        grid-template-columns: repeat(12, 1fr);
    }

    @media screen and (min-width: $tablet-wide) {
        grid-template-rows: 35vh min-content
    }

    grid-template-rows: min-content;
    width: 100%;

    .page-content {
        grid-column: 1 / 13;
        display: grid;
        @media screen and (min-width: $tablet) {
            grid-template-columns: repeat(12, 1fr);
        }
    }

}