body {
  background-color: #002F23;
  color: white;
  font-family: "Roboto Mono", monospace;
}

.App {
  padding-bottom: 0;
  background: linear-gradient(180deg, rgba(10, 27, 22, 0) 0%, #0A1B16 100%);
  background-size: 100% 128px;
  background-position: bottom center;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 30px repeat(10, 1fr) 30px;
  grid-template-rows: min-content;
  width: 100%;
}
@media screen and (min-width: 640px) {
  .App {
    grid-template-columns: repeat(12, 1fr);
  }
}
@media screen and (min-width: 800px) {
  .App {
    grid-template-rows: 35vh min-content;
  }
}
.App .page-content {
  grid-column: 1/13;
  display: grid;
}
@media screen and (min-width: 640px) {
  .App .page-content {
    grid-template-columns: repeat(12, 1fr);
  }
}

.bowlby {
  font-family: "Bowlby One", sans-serif;
}

.accent-text {
  color: #5BEDC7;
  font-weight: bold;
  font-style: italic;
}

a {
  color: #5BEDC7;
}
a:hover {
  color: #82F4D7;
  text-decoration: none;
}

.App .page-hero,
.App .contact-content {
  grid-column: 1/13;
  grid-row: 1;
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 800px) {
  .App .page-hero,
  .App .contact-content {
    grid-column: 6/13;
    grid-row: 1/3;
    height: 100%;
  }
}
@media screen and (min-width: 800px) {
  .App .page-content {
    grid-row: 1/4;
    min-height: 90vh;
    flex-grow: 0;
    grid-template-rows: 35vh auto;
  }
}
.App .splash-content {
  grid-row: 3;
  grid-column: 2/12;
  padding: 1em 0 0.75em 0;
  height: -moz-max-content;
  height: max-content;
}
@media screen and (min-width: 800px) {
  .App .splash-content {
    grid-column: 2/5;
    grid-row: 2;
  }
}
.App ul.social {
  padding: 0.5em 0;
}
.App footer.site-footer {
  grid-row: 4;
}

.App .contact-content {
  padding: 1em 0 0.75em 0;
  background-color: #0A1B16;
}
@media screen and (min-width: 800px) {
  .App .contact-content {
    padding-left: 60px;
    padding-right: 60px;
  }
}
.App iframe {
  border: none;
  border: 0;
  max-width: 750px;
  border-radius: 30px;
  overflow: hidden;
}
.App iframe:after {
  display: block;
  content: " ";
  height: 30px;
  background-color: red;
  position: absolute;
  width: 30px;
  z-index: 3;
}
.App .iframeframe {
  width: 100%;
  max-width: 750px;
  height: 80px;
  position: relative;
  top: -80px;
  background-color: #0A1B16;
  z-index: 5;
  border-radius: 0 0 30px 30px;
}

.App-header {
  grid-column: 1/13;
  grid-row: 1;
  position: relative;
  z-index: 2;
  align-self: flex-start;
  justify-self: center;
  margin-left: -6%;
}
.App-header img {
  max-width: 260px;
}
.App-header .guillotine-logo {
  padding: 1.2em 0;
}
@media screen and (min-width: 800px) {
  .App-header {
    grid-column: 2/6;
    grid-row: 1;
    align-self: flex-end;
    margin-left: -3%;
    justify-self: start;
  }
  .App-header img {
    max-width: 418px;
  }
}

.page-hero {
  height: auto;
  width: 100%;
  display: flex;
}
.page-hero img.page-hero {
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 73%);
          clip-path: polygon(0 0, 100% 0, 100% 100%, 0 73%);
}
@media screen and (min-width: 800px) {
  .page-hero img.page-hero {
    -webkit-clip-path: polygon(24% 0, 100% 0, 100% 100%, 0 100%);
            clip-path: polygon(24% 0, 100% 0, 100% 100%, 0 100%);
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: 85% center;
       object-position: 85% center;
  }
}

ul.social {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 1.2em;
  margin: 2rem 0;
}
ul.social li li.twitter {
  background-image: url(../components/social/twitter.svg);
}
ul.social li a {
  width: 24px;
  height: 24px;
  overflow: hidden;
  display: flex;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  text-indent: -999em;
  transition: all 0.1s ease-in-out;
}
ul.social li a:hover {
  transform: scale(1.2);
}

footer.site-footer {
  font-size: 12px;
  line-height: 1.4em;
  color: #14AB84;
  align-self: center;
  grid-column: 2/12;
  grid-row: 4;
  position: relative;
  z-index: 3;
}
@media screen and (min-width: 800px) {
  footer.site-footer {
    grid-column: 1/13;
    text-align: right;
    padding: 1rem 2rem;
  }
}

.button, a.link-button,
button {
  font-family: "Bowlby One", sans-serif;
  padding: 12px 28px 11px 28px;
  margin: 0;
  border: none;
  background-color: #23D7A8;
  color: #002F23;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 14px;
  letter-spacing: 1px;
  transition: all 0.2s ease-in-out;
  display: inline-flex;
  height: -moz-max-content;
  height: max-content;
}
.button:hover, a.link-button:hover,
button:hover {
  background-color: #5BEDC7;
  color: #0A1B16;
  text-shadow: 0 3px 5px rgba(0, 0, 0, 0.168627451);
}

a.link-button {
  width: -moz-max-content;
  width: max-content;
}/*# sourceMappingURL=styles.css.map */