.App {

    .page-hero,
    .contact-content {
        grid-column: 1 / 13;
        grid-row: 1;
        position: relative;
        z-index: 1;

        @media screen and (min-width: $tablet-wide) {
            grid-column: 6 / 13;
            grid-row: 1 / 3;
            height: 100%;
        }

    }

    .page-content {
    
        @media screen and (min-width: $tablet-wide) {
            grid-row: 1 / 4;
            min-height: 90vh;
            flex-grow: 0;
            grid-template-rows: 35vh auto;
        }
    }

    .splash-content {
        grid-row: 3;
        grid-column: 2 / 12;
        padding: 1em 0 .75em 0;
        height: max-content;

        @media screen and (min-width: $tablet-wide) {
            grid-column: 2 / 5;
            grid-row: 2;
        }

    }

    ul.social {
        padding: .5em 0;
    }

    footer.site-footer {
        grid-row: 4;

    }



}