footer.site-footer {
    font-size: 12px;
    line-height: 1.4em;
    color: $green-700;
    align-self: center;
    grid-column: 2 / 12;
    grid-row: 4;
    position: relative;
    z-index: 3;

    @media screen and (min-width: $tablet-wide) {
        grid-column: 1 / 13;
    
        text-align: right;
        padding: 1rem 2rem;
    }

}