.page-hero {
    height: auto;
    width: 100%;
    display: flex;

    img.page-hero {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 73%);
    }

    // at wide screen
    @media screen and (min-width: $tablet-wide) {

        img.page-hero {
            clip-path: polygon(24% 0, 100% 0, 100% 100%, 0 100%);
            object-fit: cover;
            object-position: 85% center;
            
        }

    }
    
}
